import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";

export interface BoxDetailsProps {
  keyProps: any;
  value: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.entity_background,
      borderRadius: 10,
      padding: "0px 20px",
    },
  }),
);

const TITLES: any = {
  deliveryAverageTime: "Delivery Average Time",
  loadAverageTime: "Load Average Time",
  locationAverageTime: "Location Average Time",
  preparationAverageTime: "Preparation Average Time",
  stationMetrics: "Station Metrics",
};

const SpecificBox: React.FunctionComponent<BoxDetailsProps> = ({
  keyProps,
  value,
}) => {
  const classes = useStyles();
  const showMetrics =
    keyProps !== "deliveryAverageTime" &&
    keyProps !== "stationMetrics" &&
    keyProps !== "locationAverageTime";
  console.log("sddddddddddddfsf", keyProps, value);
  return (
    <Box className={classes.root}>
      <Typography variant="h4" align="left" style={{ paddingTop: 12 }}>
        {TITLES[keyProps] || keyProps}
      </Typography>

      {showMetrics && (
        <Grid container spacing={2}>
          {["Count", "Time"].map((label, index) => (
            <React.Fragment key={label}>
              <Grid item xs={6} style={{display: "flex"}}>
                <Typography variant="body1" style={{ paddingTop: 20 }}>
                  {label}
                </Typography>
                <Typography variant="body1" style={{ paddingTop: 20 }}>
                    {": "}
                </Typography>
                <Typography variant="body1" style={{ paddingTop: 20, paddingLeft: "12px" }}>
                  {value?value[index === 0 ? "count" : "totalTime"]:0}
                </Typography>
              </Grid>
           
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default SpecificBox;
